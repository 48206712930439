
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SERVER_HOST } from '../components/service/ApiService'

const Header = () => {
  const navigate = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreenToggle = () => {
    const element = document.documentElement;

    if (!isFullScreen) {
      // Request full screen
      if (element.requestFullscreen) {
        element.requestFullscreen().catch((err) => {
          console.error('Error attempting to enable full screen:', err.message);
        });
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen().catch((err) => {
          console.error('Error attempting to enable full screen:', err.message);
        });
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen().catch((err) => {
          console.error('Error attempting to enable full screen:', err.message);
        });
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen().catch((err) => {
          console.error('Error attempting to enable full screen:', err.message);
        });
      }
    } else {
      // Exit full screen
      if (document.exitFullscreen) {
        document.exitFullscreen().catch((err) => {
          console.error('Error attempting to exit full screen:', err.message);
        });
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen().catch((err) => {
          console.error('Error attempting to exit full screen:', err.message);
        });
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen().catch((err) => {
          console.error('Error attempting to exit full screen:', err.message);
        });
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen().catch((err) => {
          console.error('Error attempting to exit full screen:', err.message);
        });
      }
    }

    // Toggle the state
    setIsFullScreen(!isFullScreen);
  };


  useEffect(() => {
    setTimeout(handleFullScreenToggle, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  document.addEventListener('fullscreenchange', () => {
    if (document.fullscreenElement)
      console.info('We are fullscreen!!!')
    else
      console.info('Do nothing...')
  })

  useEffect(() => {
    const interval = setInterval(() => {
      let time = localStorage.getItem('sessionTimer');
      if (!time) {
        localStorage.setItem('sessionTimer', new Date());
        time = new Date();
      } else {
        time = new Date(time);
      }
      let diffTime = new Date().getTime() - time.getTime();
      const lifetime = 1;
      const lateTokenTime = (lifetime * 60) - (diffTime / 1000);
      if (lateTokenTime < 1) {
        navigate('/')
      }
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <header className="sticky top-0 bg-gradient-to-r from-white from-20% via-primary-500 via-60% to-white to-80% shadow z-50">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-1 lg:px-8" aria-label="Global">
        <div className="flex justify-start h-12" onDoubleClick={handleFullScreenToggle}>
          <Link to={'/'} className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-10 w-auto" src="/mecore-logos/main-logo/logo2.gif" alt="" />
          </Link>
        </div>
        <div className="flex justify-end h-12">
          <Link to={'/'} className="-my-1.5 p-1.5">
            <img className="h-10 w-auto" src={`${SERVER_HOST}/api/v1/domain?domain=${window.location.hostname}`} alt="" />
          </Link>
        </div>
      </nav>
    </header>
  )
}

export default Header