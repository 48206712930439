import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { send } from '../components/service/api-service/services';
import { useLoading } from '../components/features/hooks/useLoading';
import { useFibaMsgBox } from '../components/features/hooks/useFibaMsgBox';
import { SERVER_HOST } from '../components/service/ApiService';

const Home = () => {
    const { showLoading } = useLoading()
    const { error } = useFibaMsgBox()

    const [module, setModule] = useState([
        // {
        //     id: 1,
        //     name: "Байгууллагын танилцуулга",
        //     description: "Хөдөлмөр, халамжийн үйлчилгээний газрын танилцуулга",
        //     logo: '/image/university.png'
        // },
        // {
        //     id: 2,
        //     name: "Хөдөлмөр эрхлэлтийн үйлчилгээ",
        //     description: "Хөдөлмөр эрхлэлтийг дэмжих үйлчилгээ",
        //     logo: '/image/407446.png'
        // },
        // {
        //     id: 3,
        //     name: "Нийгмийн халамжийн үйчилгээ",
        //     description: "Халамжийн үйлчилгээ",
        //     logo: '/image/plant.png'
        // },
    ]);

    const getMenu = async () => {
        const res = await send('mf000001', {
            page: 1,
            perPage: 500
        }, showLoading);

        if (res.status === 'error') {
            error(res.message)
        } else {
            setModule(res)
        }
    }

    useEffect(() => {
        getMenu()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='h-full'>
            <div className='mx-auto max-w-7xl gap-y-6 px-4 py-6 sm:gap-8 sm:px-6 sm:py-8 lg:px-8 lg:py-12 xl:py-16 grid grid-cols-1 gap-4 sm:grid-cols-2'>
                {module && module.map((item, index) => {
                    return <Link
                        key={index}
                        to={'/menu/' + item.id}
                        className="relative flex items-center space-x-3 rounded-lg border 
                        hover:cursor-pointer
                        border-primary-200 bg-white px-6 py-5 
                        shadow-sm focus-within:ring-2 
                        focus-within:ring-indigo-500 
                        focus-within:ring-offset-2 hover:border-primary-600"
                    >
                        <div className="flex-shrink-0">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg> */}
                            <img src={SERVER_HOST + '/api/v1/image?type=GPA&id=' + item.logo} className='w-12' alt="" />
                        </div>
                        <div className="min-w-0 flex-1">
                            <div className="focus:outline-none">
                                <span className="absolute inset-0" aria-hidden="true"></span>
                                <p className="text-lg font-medium text-gray-900">
                                    {item.name}
                                </p>
                                <p className="truncate text-sm text-gray-500">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    </Link>
                })}
            </div>
        </div>
    )
}

export default Home