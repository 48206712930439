import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { SERVER_HOST, send, useFibaMsgBox, useLoading } from '../components'
import DetailComponent from './DetailComponent'
const InformationList = () => {

    const { id } = useParams()
    const { showLoading } = useLoading()
    const { error } = useFibaMsgBox()
    const [informationList, setInformationList] = useState([])
    const [detail, setdetail] = useState({})
    const [infoid] = useState(id)

    const getMenu = async () => {
        const res = await send('mf000011', {
            id
        }, showLoading);

        if (res.status === 'error') {
            error(res.message)
        } else {
            if (res.length === 1) {
                setdetail(res[0])
            } else {
                setInformationList(res)
            }
        }
    }

    useEffect(() => {
        getMenu()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoid])

    return (
        <div>
            <nav className="flex py-2 mx-auto max-w-7xl pl-5 sticky top-12 bg-white z-50" aria-label="Breadcrumb" >
                <ol className="flex items-center space-x-4">
                    <li>
                        <div>
                            <Link to={'/'} className=" font-bold">
                                {/* <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                <span className="sr-only">Home</span> */}
                                Нүүр хуудас
                            </Link>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <svg
                                className="h-5 w-5 flex-shrink-0 text-gray-300"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                            >
                                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg>
                            <div
                                // href={page.href}
                                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                aria-current={'page'}
                            >
                                {detail.name}
                            </div>
                        </div>
                    </li>
                </ol>
            </nav>

            <div className='mx-auto max-w-7xl px-5 bg-white space-y-3 py-3'>
                {informationList.length === 0 && <DetailComponent detail={detail} />}

                {
                    informationList.length !== 0 && <>
                        <ul className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 xl:gap-x-8">
                            {informationList.map((file) => (
                                <li key={file.photo1} className="group relative">
                                    <Link
                                        to={`/menu/${id}/${file.id}`}
                                        className='relative flex items-center space-x-3 rounded-lg border-[2px] 
                                        hover:cursor-pointer p-3 
                                        shadow-sm focus-within:ring-2 bg-primary-300
                                        focus-within:ring-primary-500
                                        focus-within:ring-offset-2 hover:border-primary-700'
                                    >
                                        {/* <div className="overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75">
                                            <img
                                                src={SERVER_HOST + "/api/v1/image?type=GPA&id=" + file.photo1}
                                                alt={file.name}
                                                className="h-24 w-24 object-cover object-center"
                                            />
                                        </div> */}
                                        <p className="pointer-events-none mt-2 block truncate text-lg font-medium text-white">{file.name}</p>
                                        {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p> */}
                                    </Link>

                                </li>
                            ))}
                        </ul>
                    </>
                }
            </div>
        </div>
    )
}

export default InformationList